import React from 'react';
import axios from 'axios';

import { Dialog, DialogTitle, DialogContent, DialogActions, Box, Typography } from '@mui/material';
import { DropdownButton, Dropdown, Navbar, Nav } from 'react-bootstrap';
import { logout, isAuthenticated } from '../../utils/usuario/usuario';
import { useMediaQuery } from 'react-responsive';
import { Link ,useNavigate} from 'react-router-dom';


import logo from "../../assets/imagenes/logo512.png"

import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import './NavBar.Module.css';

function NavBar({ tipoUsuario, nombre, puntos }) {
	const idUsuario = localStorage.getItem('Usuario');



	const navigate = useNavigate();
	const [scrolled, setScrolled] = React.useState(false);

	React.useEffect(() => {
			const handleScroll = () => {
					const offset = window.scrollY;
					if (offset > 50) {
							setScrolled(true);
					} else {
							setScrolled(false);
					}
			};

			window.addEventListener('scroll', handleScroll);
			return () => {
					window.removeEventListener('scroll', handleScroll);
			};
	}, []);
	
	const isMobile = useMediaQuery({ query: '(max-width: 767px)' });

	// const uri = process.env.REACT_APP_BACK_URL || 'http://localhost:8080';

	const titleDrop = () => {
		if (isAuthenticated()) {
			return (
				<div className=' dropdowncito '>
					<p className='titleDrop'>ADMIN</p>
				</div>
			);
		} else {
			return '';
		}
	};

	const cerrarSesion = (e) => {
		e.preventDefault();
		window.location.replace('/');
		logout();
	};

	return (
		<div className='navbar navbar-expand-md border-bottom divNavBar' style={{height:isMobile && "100px", backgroundColor:(isMobile||scrolled) ? ("#2d77c0"):"#2d77c0",width:isMobile?"110vw":"100vw", marginLeft:isMobile?"-5vw":"0vw",zIndex: 4000000 }}>
			<Box onClick = {() => navigate('/iniciarsesion') } style={{position:"absolute", zIndex:2000000000, top:"10px", right: isMobile?"30px":"20px", color:"2d77c0"}} className='loggg' >
				login
			</Box>
			<Navbar
				// bg='light'
				expand='sm'
				className='container-fluid justify-content-space-between navbaaar'
				style={{ zIndex: 4000000 }}
			>
				<Navbar.Brand href='/' className='logo'>
					<img  style={{width:isMobile && "80px", border:"1px solid white", borderRadius:"50%"}}src={logo} alt='Ortodoncia invisible Rosario' />
				</Navbar.Brand>
				<Navbar.Toggle className='custom-toggle-image' aria-controls='basic-navbar-nav' />
				<Navbar.Collapse id='basic-navbar-nav'>
					<Nav className='ml-auto nav nav-pills navbar-nav me-auto mb-2 mb-md-0 derecha'>
						<section 
						style={{
							width:!isMobile?"50%":"110vw",
							// paddingLeft:"10vw",
						paddingBottom:isMobile&& "2vh",
							paddingLeft:isMobile &&"5vw" ,
							marginLeft:isMobile&&"-15px",
							paddingTop:isMobile&&"2vh",
							gap:!isMobile ? "50px":"5px", 
							backgroundColor:isMobile &&"#2d77c0",

							}} 
							className='containerNavLinks'>
								<Box 	onClick = {() => navigate('/') } sx={{textAlign:"center",width:isMobile?"90%":"160px", height:"42px", borderRadius:"30px",display:"flex", justifyContent:"center", alignContent:"center", backgroundColor:'#00B4D8',cursor: 'pointer',
												'&:hover': {
													backgroundColor: '#0077B6', // Color más oscuro al hacer hover
													boxShadow: '0px 8px 10px 0px rgba(0, 0, 0, 0.4)' // Sombra más prominente al hacer hover
												}}}>

							<Link to='/' style={{color:(isMobile||scrolled)?"#fff":"#fff"}} className='nav-link linkNavBar'>
								Inicio
							</Link>
												</Box>
							{/* <Link to='/catalogo' className='nav-link linkNavBar'>
								Market
							</Link> */}
<Box onClick ={() => navigate('/nosotros')} sx={{textAlign:"center",width:isMobile?"90%":"160px", height:"42px", borderRadius:"30px",display:"flex", justifyContent:"center", alignContent:"center", backgroundColor:'#00B4D8',cursor: 'pointer',
												'&:hover': {
													backgroundColor: '#0077B6', // Color más oscuro al hacer hover
													boxShadow: '0px 8px 10px 0px rgba(0, 0, 0, 0.4)' // Sombra más prominente al hacer hover
												}}}>

							<Link to='/nosotros' style={{color:(isMobile||scrolled)?"#fff":"#fff"}} className='nav-link linkNavBar'>
								Nosotros
							</Link>
												</Box>
							<Box onClick ={() => navigate('/contacto')}sx={{textAlign:"center",width:isMobile?"90%":"160px", height:"42px", borderRadius:"30px",display:"flex", justifyContent:"center", alignContent:"center", backgroundColor:'#00B4D8',cursor: 'pointer',
												'&:hover': {
													backgroundColor: '#0077B6', // Color más oscuro al hacer hover
													boxShadow: '0px 8px 10px 0px rgba(0, 0, 0, 0.4)' // Sombra más prominente al hacer hover
												}}}>
								<Link  to='/contacto' className='nav-link'>
								<Typography style={{ fontFamily:"Nunito",fontSize:"15px", color:"#ffff",}}>
									Contacto
								</Typography>
								</Link>
							</Box>
							{isAuthenticated() && (
								<Link onClick={(e) => cerrarSesion(e)} className='nav-link linkNavBar cerrar'>
									Cerrar sesión
								</Link>
							)}
						</section>
					</Nav>
				</Navbar.Collapse>
			</Navbar>
		</div>
	);
}

export default NavBar;
