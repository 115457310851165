import React from 'react';
import { useMediaQuery } from 'react-responsive';

import AvTimerIcon from '@mui/icons-material/AvTimer';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import BrushIcon from '@mui/icons-material/Brush';
import HdrStrongIcon from '@mui/icons-material/HdrStrong';

import { Box, Typography } from "@mui/material";

function Porque() {
  const porque = [
    { titulo: "Tiempo", descripcion: "Porque con Ortodoncia Invisible Rosario vas a tener tus alineadores listos en 10 días hábiles.", icon: <AvTimerIcon sx={{ fontSize: 16, fill: '#fff' }} /> },
    { titulo: "Comodidad", descripcion: "Porque no tomamos 'moldes', ya que contamos con un escaner intraoral.", icon: <FavoriteBorderIcon sx={{ fontSize: 16, fill: '#fff' }} /> },
    { titulo: "Personalización", descripcion: "Porque los tratamientos son acordes a tu necesidad.", icon: <BrushIcon sx={{ fontSize: 16, fill: '#fff' }} /> },
    { titulo: "Progresivo", descripcion: "Porque trabajamos con fuerzas progresivas que mueven tus dientes en menos tiempo.", icon: <HdrStrongIcon sx={{ fontSize: 16, fill: '#fff' }} /> }
  ];

  const isMobile = useMediaQuery({ query: '(max-width: 767px)' });

  return (
    <Box style={{ width: "100vw", display: "flex", justifyContent: "center", flexDirection: "column", alignItems: !isMobile ? "center" : "flex-start", paddingTop: !isMobile ? "15vh" : "5vh", paddingBottom: "5vh", backgroundColor: "#EFFCFF" }}>
      <Box style={{ width: "50vw", display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "flex-start" }}>
        <Typography sx={{ fontFamily: "Nunito", gap: '5px', display: 'flex', fontSize: isMobile ? "18px" : '25px', fontWeight: '400', alignItems: 'center', color: '#0077B6', marginBottom: "20px", paddingLeft: isMobile && "10px" }}>
          Por qué elegirnos
        </Typography>
      </Box>
      <Box style={{ width: "100%", display: "flex", flexDirection: isMobile ? "column" : "row", gap: isMobile ? "20px" : "45px", alignItems: isMobile && "center", justifyContent: "center" }}>
        {porque.map((v, index) => (
          <Box key={index} sx={{ display:"flex",flexDirection:"column", justifyContent:"space-around",  width: "255px", height: "279px", borderRadius: "20px", position: "relative", padding: "15px",background: "linear-gradient(0deg, #0077B6 0%, #d9d9d926 100%)" }}>
             {/* <Box sx={{borderRadius: "20px", position: "absolute", bottom: "0px",   background: "linear-gradient(0deg, #0077B6 0%, #d9d9d926 100%)",  }}> */}
            <Typography sx={{ fontFamily: "Nunito", gap: '5px', display: 'flex', fontSize: '21px', fontWeight: '700', textAlign: "left", alignItems: 'center', color: '#0077B6', marginBottom: "15px", paddingLeft: "15px" }}>
              {v.titulo}
            </Typography>
            <Typography sx={{ paddingLeft: "15px", fontFamily: "Nunito", gap: '5px', display: 'flex', fontSize: '16px', fontWeight: '400', textAlign: "left", alignItems: 'center', color: '#fff', marginBottom: "20px" }}>
              {v.descripcion}
            </Typography>
            <Box sx={{ position: 'absolute', bottom: '15px', left: '15px', width:"32px", height:"32px", borderRadius:"50%", backgroundColor:"#00B4D8", display:"flex", justifyContent:"center", alignItems:"center"}}>
              {v.icon}
            </Box>
            {/* </Box> */}
          </Box>
        ))}
      </Box>
    </Box>
  );
}

export default Porque;
