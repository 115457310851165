import React from 'react';
import toast from 'react-hot-toast';
import './Ventajas.Module.css';
import { isAuthenticated } from '../../utils/usuario/usuario';
import axios from 'axios';
import KeyboardArrowRightRoundedIcon from '@mui/icons-material/KeyboardArrowRightRounded';
import { useMediaQuery } from 'react-responsive';
import {
  Avatar,
  Box,
  Badge,
  Button,
  IconButton,
  Typography,
  FormControlLabel,
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
Accordion,
AccordionSummary,
AccordionDetails
} from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';



function Ventajas() {
	const token = localStorage.getItem('Token');
const authenticated=isAuthenticated()
const [ventajas, setVentajas] = React.useState([])

const [showEditImages, setShowEditImages] = React.useState(false)

const handleShowEditOpen =() => {
  setShowEditImages(true)
}
const handleShowEditClose =() => {
  setShowEditImages(false)
}

const [imageChangeKey, setImageChangeKey] = React.useState(0);

	const config = {
		headers: {
			xtoken: `${token}`,
		},
	};
  React.useEffect(() => {
		const cargarImageTop = async () => {
			const { data } = await axios.get(`${process.env.REACT_APP_BACK_URL}/api/imagenes?tipo=VENTAJAS`);
			setVentajas(data.imagenes);
		};
		cargarImageTop();
	 } , []);


  //  console.log(ventajas)

	const [loader, setLoader] = React.useState(true);
	const [image, setImage] = React.useState('');
	const [renderKey, setRenderKey] = React.useState(0);
	const isSuperAdmin = isAuthenticated();
	const isMobile = useMediaQuery({ query: '(max-width: 767px)' });
	const [isWidgetOpened, setWidgetOpened] = React.useState(false);
  const widgetRef = React.useRef(null);
	const handleImageChange = async (image, index) => {
    const widgetOptions = {
      cloudName: 'duomlqsl4', // Reemplaza con el nombre de tu cuenta en Cloudinary
      uploadPreset: 'ml_default', // Reemplaza con el nombre del preset de carga en Cloudinary
      cropping: false, // Deshabilitar la opción de recorte
      sources: ['local', 'url', 'camera'], // Permitir cargar desde archivos locales, URL o cámara
      multiple: false, // Permitir solo una imagen a la vez
      resourceType: 'image', // Permitir solo la carga de imágenes
      clientAllowedFormats: ['jpg', 'png', 'jpeg'], // Formatos permitidos (puedes agregar otros si lo deseas)
      maxFileSize: 5000000, // Tamaño máximo del archivo (en bytes), aquí son 5 MB
    };
  
    try {
      if (widgetRef.current) {
        setWidgetOpened(true);
        widgetRef.current.open();
      } else {
        const widget = window.cloudinary.createUploadWidget(
          widgetOptions,
          async (error, result) => {
            if (!error && result && result.event === 'success' && result.info.secure_url) {
              const url = result.info.secure_url; //se guarda la url nueva de la img que se subio
  
              // Actualizar la imagen en el estado local
              setVentajas((prevImages) =>
                prevImages.map((image, i) => (i === index ? { ...url, url } : image))
              );
  
              // Actualizar la imagen en el backend
              await axios.put(
                `${process.env.REACT_APP_BACK_URL}/api/imagenes/${image._id}?url=${url}`,
                '',
                config
              );
  
              // Incrementar el estado imageChangeKey para forzar la recarga del componente
              setImageChangeKey((prevKey) => prevKey + 1);
            } else if (error) {
              console.error('Error al cargar la imagen:', error);
            }
          }
        );
  
        widgetRef.current = widget;
        widget.open();
      }
    } catch (error) {
      console.error('Error al eliminar la imagen:', error);
    }
  };

	return (
		<Box style={{width:"100vw", display:"flex", justifyContent:"center", flexDirection:"column", alignItems:!isMobile?"center":"flex-start", paddingTop:!isMobile?"15vh":"5vh", paddingBottom:"5vh",backgroundColor:"#EFFCFF"}}>


<Box style={{width:"50vw", display:"flex", justifyContent:"center", flexDirection:"column", alignItems:"flex-start"}}>

<Typography sx={{fontFamily:"Nunito", gap: '5px', display: 'flex', fontSize: isMobile?"18px":'25px', fontWeight: '400', alignItems: 'center', color: '#0077B6', marginBottom:"20px", paddingLeft:isMobile&&"10px"}}>
					Ventajas {
          authenticated && (
          <EditIcon sx={{cursor:"pointer"}} onClick={handleShowEditOpen}/>
        
        )}
				</Typography>
</Box> 

<Box style={{width:"100%",display:"flex", gap:isMobile?"20px":"45px", justifyContent:!isMobile?"center":"space-around"}}>
{
	ventajas?.map((v, index)=>(

<Box key={index} sx={{ width:isMobile?"180px":"475px", height: isMobile?"250px":"589px", borderRadius: "20px", position: "relative" }}>
  <img style={{ width:isMobile?"180px":"475px", height: isMobile?"250px":"589px", objectFit: "cover", borderRadius: "20px" }} src={v.url} alt={`img de ${v.nombre}`} />
  <Box sx={{borderRadius: "20px", position: "absolute", bottom: "0px",   background: "linear-gradient(0deg, #0077B6 0%, #d9d9d926 100%)",  }}>
    <Typography sx={{ fontFamily: "Nunito", gap: '5px', display: 'flex', fontSize: isMobile ? "18px" : '36px', fontWeight: '400',textAlign:"left" , alignItems: 'center', color: '#fff', marginBottom: "15px", paddingLeft:"15px" }}>
      {v.nombre}
    </Typography>
    <Typography sx={{  paddingLeft:"15px" ,fontFamily: "Nunito", gap: '5px', display: 'flex', fontSize: isMobile ? "12px" : '19px', fontWeight: '400',textAlign:"left" ,alignItems: 'center', color: '#fff', marginBottom: "20px" }}>
      {v.descripcion}
    </Typography>
  </Box>
</Box>
	))
}
</Box>
<Dialog open={showEditImages} onClose={handleShowEditClose}>
      <DialogTitle>Editar imagenes</DialogTitle>
      <DialogContent>
        <Box sx={{ width: "100%" }}>
          <Grid container spacing={2} justifyContent="center">
            {ventajas?.map((producto, index) => (
              <Grid item key={index}>
                <Box sx={{ position: "relative", width: "150px", height: "150px" }}>
                  <img
                    style={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      width: "100%",
                      height: "100%",
                      objectFit: "cover"
                    }}
                    src={producto.url}
                    alt={`Imagen de ${producto.nombre}`}
                  />
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      backgroundColor: "#fff",
                      width: "35px",
                      height: "35px",
                      borderRadius: "50%",
                      position: "absolute",
                      top: 8,
                      right: 8,
                      cursor: "pointer"
                    }}
                    onClick={() => handleImageChange(producto, index)}
                  >
                    <EditIcon/>
                  </div>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Box>
      </DialogContent>
    </Dialog>
		</Box>
	);
}

export default Ventajas;
