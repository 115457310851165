import React from 'react';
import Formulario1 from '../../components/formulario1/Formulario1';
import Formulario2 from '../../components/formulario2/Formulario2';
import Footer from '../../components/footer/Footer';
import Porque from '../../components/porque/Porque.jsx';


import {
  Avatar,
  Box,
  Badge,
  IconButton,
  Typography,
  FormControlLabel,
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
Accordion,
AccordionSummary,
AccordionDetails
} from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import GloboContacto from '../../components/globoContacto/GloboContacto';
import Loader from '../../components/loader/Loader';
import NavBar from '../../components/navBar/NavBar';
import YouTube from 'react-youtube';
import Preguntas from '../../components/Preguntas/Preguntas.jsx';
import Ventajas from '../../components/ventajas/Ventajas.jsx';
import Testimonios from '../../components/testimonios/Testimonios.jsx';
import Video from "../../assets/videos/video3.mp4"
import {useNavigate} from 'react-router-dom';

import { useMediaQuery } from 'react-responsive';

import Stamp from '../../components/stamp/Stamp';
import scroll from '../../assets/imagenes/scroll-bar.png';

import { getProductos, getDatos } from '../../utils/productos/productos';
import './Home.Module.css';
import CarouselImg from '../../components/carousel/Carousel.jsx';

import { isAuthenticated } from '../../utils/usuario/usuario';
import { Toaster, toast } from 'react-hot-toast';
import { Button } from 'react-bootstrap';
import axios from 'axios';

import ReactMarkdown from 'react-markdown';

function Home() {
	const navigate = useNavigate();
	const token = localStorage.getItem('Token');
	const config = {
		headers: {
			xtoken: `${token}`,
		},
	};
	const [productos, setProductos] = React.useState([]);
	const [datosContacto, setDatosContacto] = React.useState([]);
const authenticated = isAuthenticated()
// console.log("autenti", authenticated)
	const isMobile = useMediaQuery({ query: '(max-width: 767px)' });

	const [viewScroll, setViewScroll] = React.useState(false);
	const [showEditImages, setShowEditImages] = React.useState(false)
	const [renderKey, setRenderKey] = React.useState(0);
	const handleShowEditOpen =() => {
		setShowEditImages(true)
	}
	const handleShowEditClose =() => {
		setShowEditImages(false)
	}
	const [isWidgetOpened, setWidgetOpened] = React.useState(false);
  const widgetRef = React.useRef(null);
	const [imageChangeKey, setImageChangeKey] = React.useState(0);
	const [loader, setLoader] = React.useState(true);
const [imageTop, setImageTop] = React.useState('');
	const [video, setVideo] = React.useState('');
	const [isEditing, setIsEditing] = React.useState(false);
	const [newVideo, setNewVideo] = React.useState({});

	const videoId = video[0]?.url?.split('v=')[1];

	const opts = {
		width: '100%',
		heigth: '100%',
		playerVars: {
			// Opciones del reproductor, como el autoplay
			autoplay: false,
		},
	};

	const handleImageChange = async (image, index) => {
    const widgetOptions = {
      cloudName: 'duomlqsl4', // Reemplaza con el nombre de tu cuenta en Cloudinary
      uploadPreset: 'ml_default', // Reemplaza con el nombre del preset de carga en Cloudinary
      cropping: false, // Deshabilitar la opción de recorte
      sources: ['local', 'url', 'camera'], // Permitir cargar desde archivos locales, URL o cámara
      multiple: false, // Permitir solo una imagen a la vez
      resourceType: 'image', // Permitir solo la carga de imágenes
      clientAllowedFormats: ['jpg', 'png', 'jpeg'], // Formatos permitidos (puedes agregar otros si lo deseas)
      maxFileSize: 15000000, // Tamaño máximo del archivo (en bytes), aquí son 5 MB
    };
  
    try {
      if (widgetRef.current) {
        setWidgetOpened(true);
        widgetRef.current.open();
      } else {
        const widget = window.cloudinary.createUploadWidget(
          widgetOptions,
          async (error, result) => {
            if (!error && result && result.event === 'success' && result.info.secure_url) {
              const url = result.info.secure_url; //se guarda la url nueva de la img que se subio
  
              // Actualizar la imagen en el estado local
              setImageTop((prevImages) =>
                prevImages.map((image, i) => (i === index ? { ...url, url } : image))
              );
  
              // Actualizar la imagen en el backend
              await axios.put(
                `${process.env.REACT_APP_BACK_URL}/api/imagenes/${image._id}?url=${url}`,
                '',
                config
              );
  
              // Incrementar el estado imageChangeKey para forzar la recarga del componente
              setImageChangeKey((prevKey) => prevKey + 1);
            } else if (error) {
              console.error('Error al cargar la imagen:', error);
            }
          }
        );
  
        widgetRef.current = widget;
        widget.open();
      }
    } catch (error) {
      console.error('Error al eliminar la imagen:', error);
    }
  };



	 React.useEffect(() => {
		const cargarImageTop = async () => {
			const { data } = await axios.get(`${process.env.REACT_APP_BACK_URL}/api/imagenes?tipo=LANDING`);
			setImageTop(data.imagenes);
			setLoader(false)
		};
		cargarImageTop();
	 } , []);

	const handleEditVideo = () => {
		setIsEditing(true);
		setNewVideo({
			url: video[0]?.url,
			descripcion: video[0]?.descripcion,
		});
	};

	const handleCancelEditVideo = () => {
		setIsEditing(false);
	};

	const handleSaveVideo = async () => {
		try {
			// Envía la nueva URL y descripción en el cuerpo de la solicitud
			const response = await axios.put(
				`${process.env.REACT_APP_BACK_URL}/api/imagenes/${video[0]._id}?url=${newVideo.url}`,
				{ descripcion: newVideo.descripcion },
				config
			);

			// Actualiza el estado del video con los datos actualizados
			setVideo([{ url: newVideo.url, descripcion: newVideo.descripcion }]);
			setIsEditing(false);
			toast.success('Video actualizado con éxito!');
		} catch (error) {
			toast.error('Hubo un problema al cambiar el video');
		}
	};

	const handleVideoInputChange = (event) => {
		const { name, value } = event.target;
		setNewVideo({ ...newVideo, [name]: value });
	};
// console.log(imageTop)
	return (
		<>
			{loader ? (
				<Loader />
			) : (
				<div className='fondoHome'>

			
				 <header>
						<NavBar />
					</header>
					<main className='mainHome'>
						<>
							<Toaster
								position='bottom-left'
								reverseOrder={false}
								toastOptions={{ className: 'fondoToast' }}
							/>
							<section style={{width:"100vw", height:isMobile?"50vh":"92vh"}}className='sectionCarousel'>
								<Box 
								   className={!isMobile && "blur-top-edge"}
								style={{width:"100%", height:"100%", 
								 backgroundRepeat: 'no-repeat', 
								 backgroundSize: 'cover', 
								 backgroundPosition: 'center' ,
								backgroundImage: `url(${imageTop[0]?.url})`,
								paddingTop:"10%",
								paddingLeft:"15%",
								marginTop:isMobile && "13vh"
							}}
								
								>
 {
          authenticated && (
<Typography sx={{fontFamily:"Nunito", gap: '5px', display: 'flex', marginTop:isMobile && "40vh",fontSize: isMobile?"18px":'25px', fontWeight: '400', alignItems: 'center', color: '#0077B6', marginBottom:"20px" }}>
					Editar Imagen 
          <EditIcon sx={{cursor:"pointer", }} onClick={handleShowEditOpen}/>
        
     
				</Typography>
			)}
										{!isMobile && (

<Box style={{width: "314px", display:"flex", flexDirection:"column", gap:"38px"}}

>
										<Box style={{display:"flex", flexDirection:"column", alignItems:"flex-start", justifyContent:"center"}}>
										<Typography style={{fontFamily:"Nunito",color:"#0077B6", fontWeight:800, fontSize:isMobile?"24px":"60px"}}>Ortodoncia</Typography>
										<Typography style={{fontFamily:"Nunito",color:"#0077B6", fontWeight:800, fontSize:isMobile?"24px":"60px"}}>Invisible</Typography>
										<Typography style={{fontFamily:"Nunito",color:"#0077B6", fontWeight:800, fontSize:isMobile?"24px":"60px"}}>Rosario</Typography>
										</Box>
										<Box 
										onClick ={() => navigate('/contacto')}
										sx={{
											width: isMobile? "150px":"346px", // px se asume por defecto, pero puedes especificar otra unidad si es necesario
											height: isMobile? "42px":"90px",
											backgroundColor: '#00B4D8',
											borderRadius: 56,
											display: 'flex',
											justifyContent: 'center',
											alignItems: 'center',
											boxShadow: '0px 5px 5px 0px rgba(0, 0, 0, 0.25)',
											cursor: 'pointer',
											'&:hover': {
												backgroundColor: '#0077B6', // Color más oscuro al hacer hover
												boxShadow: '0px 8px 10px 0px rgba(0, 0, 0, 0.4)' // Sombra más prominente al hacer hover
											}
										}}
											>
										<Typography style={{fontFamily:"Nunito",color:"#ffff", fontWeight:800, fontSize:isMobile?"20px":"32px"}}>Contactanos</Typography>
											</Box>

									</Box>
										)}
							


								</Box>
							</section>

							<section className='sectionQ&a'>
								<Ventajas />
							</section>

							<section style={{width:"100vw" }}>
								<Box>
								<video src={Video} autoPlay={true} loop={true} muted style={{ width: '100%', maxHeight: '80vh' }} />
								
								</Box>
							</section>
							<section className='sectionQ&a'>
								<Porque />
							</section>
							<section className='sectionQ&a'>
								<Testimonios />
							</section>
							{/* <section className='sectionAbout'>
								<Formulario1 />
							</section> */}
							<section className='sectionQ&a'>
								<Preguntas />
							</section>
							<Dialog open={showEditImages} onClose={handleShowEditClose}>
      <DialogTitle>Editar imagenes</DialogTitle>
      <DialogContent>
        <Box sx={{ width: "100%" }}>
          <Grid container spacing={2} justifyContent="center">
            {imageTop && imageTop?.map((producto, index) => (
              <Grid item key={index}>
                <Box sx={{ position: "relative", width: "150px", height: "150px" }}>
                  <img
                    style={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      width: "100%",
                      height: "100%",
                      objectFit: "cover"
                    }}
                    src={producto.url}
                    alt={`Imagen de ${producto.nombre}`}
                  />
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      backgroundColor: "#fff",
                      width: "35px",
                      height: "35px",
                      borderRadius: "50%",
                      position: "absolute",
                      top: 8,
                      right: 8,
                      cursor: "pointer"
                    }}
                    onClick={() => handleImageChange(producto, index)}
                  >
                    <EditIcon/>
                  </div>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Box>
      </DialogContent>
    </Dialog>
							<GloboContacto />
						</>
					</main>
					<footer>
						<Footer />
					</footer> 
				</div>
			)}
		</>
	);
}

export default Home;
