import React from 'react';
import './Footer.Module.css';

function Footer() {
	return (
		<div className='d-flex flex-wrap justify-content-start align-items-end border-top divFooter '>
			<h6>
				desarrollado por&nbsp;
				<a className='terminosFoo' href='https://instagram.com/mgsdeveloper' target='blank'>
					mgsdev
				</a>
			</h6>
			<h6>| Todos los derechos reservados © 2024 - 2025</h6>
		</div>
	);
}

export default Footer;
