import React from 'react';
import NavBar from '../../components/navBar/NavBar';
import Footer from '../../components/footer/Footer';
import GloboContacto from '../../components/globoContacto/GloboContacto';
import {
  Avatar,
  Box,
  Badge,
  IconButton,
  Typography,
  FormControlLabel,
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
Accordion,
AccordionSummary,
AccordionDetails
} from "@mui/material";
import { Toaster, toast } from 'react-hot-toast';
import './Nosotros.Module.css';
import CarouselImg from '../../components/carousel/Carousel';
import Loader from '../../components/loader/Loader';
import axios from 'axios';
import ReactMarkdown from 'react-markdown';

import 'react-multi-carousel/lib/styles.css'; // Importa los estilos del carousel
import { isAuthenticated } from '../../utils/usuario/usuario';

export default function Nosotros() {

const [renderKey, setRenderKey] = React.useState(0)

const [nuevoTexto, setNuevoTexto] = React.useState("")

const [editTex, setEditText] = React.useState(false)

const handleEditTextOpen = () => {
	setEditText(true)
}

const handleEditTextClose = () => {
	setEditText(false)
}

async function handleSaveDescription() {
	const data = {
		descripcion: nuevoTexto,
	};
	// Puedes guardar la descripción en el estado local y/o enviarla al backend si lo deseas
	await axios.put(`${process.env.REACT_APP_BACK_URL}/api/imagenes/${image[0]._id}`, data, config);
	handleEditTextClose()
	setImage([{ ...image[0], descripcion: nuevoTexto }]);
	toast.success("Descripción actualizada")
	setRenderKey((prevKey) => prevKey + 1);
	// Tu código para guardar la descripción en el backend si es necesario
}

	const token = localStorage.getItem('Token');
	const config = {
		headers: {
			xtoken: `${token}`,
		},
	};

	const [isWidgetOpened, setWidgetOpened] = React.useState(false);
  const widgetRef = React.useRef(null);

	const handleImageChange = async () => {
    const widgetOptions = {
      cloudName: 'duomlqsl4', // Reemplaza con el nombre de tu cuenta en Cloudinary
      uploadPreset: 'ml_default', // Reemplaza con el nombre del preset de carga en Cloudinary
      cropping: false, // Deshabilitar la opción de recorte
      sources: ['local', 'url', 'camera'], // Permitir cargar desde archivos locales, URL o cámara
      multiple: false, // Permitir solo una imagen a la vez
      resourceType: 'image', // Permitir solo la carga de imágenes
      clientAllowedFormats: ['jpg', 'png', 'jpeg'], // Formatos permitidos (puedes agregar otros si lo deseas)
      maxFileSize: 15000000, // Tamaño máximo del archivo (en bytes), aquí son 5 MB
    };
  
    try {
      if (widgetRef.current) {
        setWidgetOpened(true);
        widgetRef.current.open();
      } else {
        const widget = window.cloudinary.createUploadWidget(
          widgetOptions,
          async (error, result) => {
            if (!error && result && result.event === 'success' && result.info.secure_url) {
              const url = result.info.secure_url; //se guarda la url nueva de la img que se subio
  
  
              // Actualizar la imagen en el backend
              await axios.put(
                `${process.env.REACT_APP_BACK_URL}/api/imagenes/${image[0]._id}?url=${url}`,
                '',
                config
              );
  toast.success("Imagen actualizada")
              // Incrementar el estado imageChangeKey para forzar la recarga del componente
              setImageChangeKey((prevKey) => prevKey + 1);
            } else if (error) {
              console.error('Error al cargar la imagen:', error);
            }
          }
        );
  
        widgetRef.current = widget;
        widget.open();
      }
    } catch (error) {
      console.error('Error al eliminar la imagen:', error);
    }
  };
	const [imageChangeKey, setImageChangeKey] = React.useState(0);
	const [image, setImage] = React.useState([])
	const [loader, setLoader] = React.useState(false);

	const scrollToTop = () => {
		window.scrollTo({ top: 0, behavior: 'smooth' });
	};

	React.useEffect(() => {
		const cargarImage= async () => {
			const { data } = await axios.get(`${process.env.REACT_APP_BACK_URL}/api/imagenes?tipo=NOSOTROS`);
			setImage(data.imagenes);
			setNuevoTexto(data.imagenes[0]?.descripcion)
		};
		cargarImage();
	 } , [renderKey, imageChangeKey]);

	React.useEffect(() => {
		setLoader(true);
		setTimeout(() => {
			setLoader(false);
		}, 500);
		scrollToTop();
	}, []);



	return (
		<div className='fondoDetail' style={{ overflowX: 'hidden' }}>
			<header>
				<NavBar />
			</header>
			{loader ? (
				<Loader />
			) : (
				<>
					<main  style={{ display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center", backgroundImage: `url(${image[0]?.url})`,overflowX: 'hidden' }} className='mainAbout py-4'>

					
							<Toaster
								position='bottom-left'
								reverseOrder={false}
								toastOptions={{ className: 'fondoToast' }}
					
								/>
						<div className='containerAbout py-4 shadow'>
							{/* <div className='carouselAbout'>
								<CarouselImg />
							</div> */}

							{/* Contenido de texto */}
							<Typography className='aboutText'>
  {image[0]?.descripcion?.split('/n').map((paragraph, index) => (
    <div key={index}>
      <Typography 
        key={index+1} 
        style={{ fontFamily: "Nunito", fontSize:"16px",textAlign: "center", color: "#474747" }} 
        gutterBottom
      >
        {paragraph.split(/(\*.*?\*)/).map((text, subIndex) => {
          if (text.startsWith('*') && text.endsWith('*')) {
            return <strong key={subIndex+2}>{text.slice(1, -1)}</strong>;
          } else {
            return text;
          }
        })}
      </Typography>
    </div>
  ))}
</Typography>
						</div>
{
	isAuthenticated() && (
		<Box sx={{display:"flex", gap:"25px", marginTop:"25px"}}>
		<Box onClick={() => handleImageChange()} className='editDatos'
		// sx={{height:"42px", width:"180px", cursor:"pointer", backgroundColor:"#d9d9d9", borderRadius:"5px",display:"flex", justifyContent:"center", alignItems:"center"}}
		>
			<Typography sx={{color:"#fff"}}>
				Actualizar Imagen
			</Typography>
		</Box>

		<Box onClick={() => handleEditTextOpen()}className='editDatos' 
		// sx={{height:"42px", width:"180px", cursor:"pointer",backgroundColor:"#d9d9d9",borderRadius:"5px", display:"flex", justifyContent:"center", alignItems:"center"}}
		>
				<Typography sx={{color:"#fff"}}>
				Actualizar Texto
			</Typography>
		</Box>

		</Box>
	)
}

<Dialog open={editTex} onClose={handleEditTextClose}>
<DialogContent>
<textarea
							value={nuevoTexto}
							onChange={(e) => setNuevoTexto(e.target.value)}
							rows='4'
							cols='50'
						/>
						<Box sx={{height:"42px", width:"180px", cursor:"pointer",backgroundColor:"#d9d9d9",borderRadius:"5px", display:"flex", justifyContent:"center", alignItems:"center"}} onClick={handleSaveDescription}>
							Guardar descripción
							</Box>
</DialogContent>
</Dialog>
					
					</main>
					<GloboContacto />
					<footer>
						<Footer />
					</footer>
				</>
			)}
		</div>
	);
}
