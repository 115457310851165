import React from 'react';
import toast from 'react-hot-toast';
import './Testimonios.Module.css';
import { isAuthenticated } from '../../utils/usuario/usuario';
import axios from 'axios';
import KeyboardArrowRightRoundedIcon from '@mui/icons-material/KeyboardArrowRightRounded';
import { useMediaQuery } from 'react-responsive';
import Carousel from 'react-multi-carousel';
import {
  Avatar,
  Box,
  Badge,
  Button,
  IconButton,
  Typography,
  FormControlLabel,
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
Accordion,
AccordionSummary,
AccordionDetails
} from "@mui/material";

import EditIcon from '@mui/icons-material/Edit';
import zIndex from '@mui/material/styles/zIndex';

function Testimonios() {
	const token = localStorage.getItem('Token');

const [testimonios, setTestimonios] = React.useState([])

const [showEditImages, setShowEditImages] = React.useState(false)
const authenticated = isAuthenticated()
const handleShowEditOpen =() => {
  setShowEditImages(true)
}
const handleShowEditClose =() => {
  setShowEditImages(false)
}

const [imageChangeKey, setImageChangeKey] = React.useState(0);

	const config = {
		headers: {
			xtoken: `${token}`,
		},
	};
  React.useEffect(() => {
		const cargarImageTop = async () => {
			const { data } = await axios.get(`${process.env.REACT_APP_BACK_URL}/api/imagenes?tipo=ANTES_DESPUES`);
			setTestimonios(data.imagenes);
		};
		cargarImageTop();
	 } , []);


   const responsive = {
		desktop: {
			breakpoint: { max: 3000, min: 1024 },
			items: 3,
			slidesToSlide: 1,
		},
		tablet: {
			breakpoint: { max: 1024, min: 464 },
			items: 2,
			slidesToSlide: 1,
		},
		mobile: {
			breakpoint: { max: 464, min: 0 },
			items: 1,
			slidesToSlide: 1,
		},
	};


	const [loader, setLoader] = React.useState(true);
	const [image, setImage] = React.useState('');
	const [renderKey, setRenderKey] = React.useState(0);
	const isSuperAdmin = isAuthenticated();
	const isMobile = useMediaQuery({ query: '(max-width: 767px)' });
  const [isWidgetOpened, setWidgetOpened] = React.useState(false);
  const widgetRef = React.useRef(null);


  const handleImageChange = async (image, index) => {
    const widgetOptions = {
      cloudName: 'duomlqsl4', // Reemplaza con el nombre de tu cuenta en Cloudinary
      uploadPreset: 'ml_default', // Reemplaza con el nombre del preset de carga en Cloudinary
      cropping: false, // Deshabilitar la opción de recorte
      sources: ['local', 'url', 'camera'], // Permitir cargar desde archivos locales, URL o cámara
      multiple: false, // Permitir solo una imagen a la vez
      resourceType: 'image', // Permitir solo la carga de imágenes
      clientAllowedFormats: ['jpg', 'png', 'jpeg'], // Formatos permitidos (puedes agregar otros si lo deseas)
      maxFileSize: 5000000, // Tamaño máximo del archivo (en bytes), aquí son 5 MB
    };
  
    try {
      if (widgetRef.current) {
        setWidgetOpened(true);
        widgetRef.current.open();
      } else {
        const widget = window.cloudinary.createUploadWidget(
          widgetOptions,
          async (error, result) => {
            if (!error && result && result.event === 'success' && result.info.secure_url) {
              const url = result.info.secure_url; //se guarda la url nueva de la img que se subio
  
              // Actualizar la imagen en el estado local
              setTestimonios((prevImages) =>
                prevImages.map((image, i) => (i === index ? { ...url, url } : image))
              );
  
              // Actualizar la imagen en el backend
              await axios.put(
                `${process.env.REACT_APP_BACK_URL}/api/imagenes/${image._id}?url=${url}`,
                '',
                config
              );
  
              // Incrementar el estado imageChangeKey para forzar la recarga del componente
              setImageChangeKey((prevKey) => prevKey + 1);
            } else if (error) {
              console.error('Error al cargar la imagen:', error);
            }
          }
        );
  
        widgetRef.current = widget;
        widget.open();
      }
    } catch (error) {
      console.error('Error al eliminar la imagen:', error);
    }
  };

	return (
		<Box style={{width:"100vw", display:"flex", justifyContent:"center", flexDirection:"column", alignItems:!isMobile?"center":"flex-start", paddingTop:!isMobile?"15vh":"5vh", paddingBottom:"5vh",backgroundColor:"#EFFCFF"}}>


<Box style={{zIndex:1, width:"50vw", display:"flex", justifyContent:"center", flexDirection:"column", alignItems:"flex-start"}}>

<Typography sx={{fontFamily:"Nunito", gap: '5px', display: 'flex', fontSize: isMobile?"18px":'25px', fontWeight: '400', alignItems: 'center', color: '#0077B6', marginBottom:"20px", paddingLeft:isMobile&&"10px" }}>
					Casos reales  {
          isSuperAdmin && (
          <EditIcon sx={{cursor:"pointer"}} onClick={handleShowEditOpen}/>
        
        )}
				</Typography>
       
        
</Box> 

<Box style={{width:isMobile?"100vw":"60%", gap:isMobile?"20px":"45px", justifyContent:!isMobile?"center":"space-around"}}>
<Carousel

draggable={true}
showDots={false}
swipeable={true}
responsive={responsive}
infinite={true}
autoPlay={true}
keyBoardControl={true}
autoPlaySpeed={isMobile?2500:5000}
shouldResetAutoplay={false} 
pauseOnHover={false}
// containerClass="carousel-container"
removeArrowOnDeviceType={["mobile"]}
>
{testimonios?.map((producto, index) => (
  <img style={{width:isMobile?"250px":"350px",
  borderRadius:"20px",
  //  clipPath: "polygon(52% 21%, 80% 10%, 100% 21%, 100% 49%, 86% 73%, 50% 100%, 16% 75%, 0 50%, 0 23%, 20% 10%)",
  height:isMobile?"250px":"350px", objectFit:"cover"}} key={index} src={producto.url} alt={`Imagen de ${producto.nombre}`} />
))}
</Carousel>

<Dialog open={showEditImages} onClose={handleShowEditClose}>
      <DialogTitle>Editar imagenes</DialogTitle>
      <DialogContent>
        <Box sx={{ width: "100%" }}>
          <Grid container spacing={2} justifyContent="center">
            {testimonios?.map((producto, index) => (
              <Grid item key={index}>
                <Box sx={{ position: "relative", width: "150px", height: "150px" }}>
                  <img
                    style={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      width: "100%",
                      height: "100%",
                      objectFit: "cover"
                    }}
                    src={producto.url}
                    alt={`Imagen de ${producto.nombre}`}
                  />
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      backgroundColor: "#fff",
                      width: "35px",
                      height: "35px",
                      borderRadius: "50%",
                      position: "absolute",
                      top: 8,
                      right: 8,
                      cursor: "pointer"
                    }}
                    onClick={() => handleImageChange(producto, index)}
                  >
                    <EditIcon/>
                  </div>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Box>
      </DialogContent>
    </Dialog>

</Box>
		</Box>
	);
}

export default Testimonios;
