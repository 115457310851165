import React from 'react';
import toast from 'react-hot-toast';
import './Preguntas.Module.css';
import { isAuthenticated } from '../../utils/usuario/usuario';
import axios from 'axios';
import KeyboardArrowRightRoundedIcon from '@mui/icons-material/KeyboardArrowRightRounded';
import { useMediaQuery } from 'react-responsive';
import {
  Avatar,
  Box,
  Badge,
  Button,
  IconButton,
  Typography,
  FormControlLabel,
  Checkbox,
  Dialog,
Accordion,
AccordionSummary,
AccordionDetails
} from "@mui/material";

function Preguntas() {
	const token = localStorage.getItem('Token');
	const config = {
		headers: {
			xtoken: `${token}`,
		},
	};

	const [loader, setLoader] = React.useState(true);
	const [image, setImage] = React.useState('');
	const [renderKey, setRenderKey] = React.useState(0);
	const isSuperAdmin = isAuthenticated();
	const isMobile = useMediaQuery({ query: '(max-width: 767px)' });
		
const preguntas = [
	{
		pregunta:"¿Cuáles son los pasos a seguir?",
		respuesta:"¡Lo primero es el contacto de Ortodoncia Invisible Rosario con vos! Nos pondremos en contacto para agendar una charla, o responder a todas tus preguntas vía whatsapp. Si estás interesado en continuar su tratamiento, se le remitirá nuestra clínica de diagnóstico por imágenes asociadas."
	},
	{
		pregunta:"¿Los alineadores invisibles son lo que necesito?",
		respuesta:"El tratamiento con alineadores fue diseñado para atender diferentes casos de ortodoncia, con diferentes niveles de complejidad. Para responder a esta pregunta, deberás realizar una primera consulta, para que podamos evaluar tu caso."
	},
	{
		pregunta:"¿Cuánto tiempo lleva comenzar el tratamiento?",
		respuesta:"El tratamiento comienza en un plazo de 10 días hábiles."
	},
	{
		pregunta:"¿Cómo funciona el tratamiento con alineadores invisibles?",
		respuesta:"Primero, pasarás por nuestro examen en línea, donde nuestros expertos evaluarán previamente tu condición y responderán todas tus preguntas sobre el tratamiento con los alineadores. Luego lo derivarán a la clínica de imágenes asociada, donde se le realizarán los exámenes necesarios para comenzar el tratamiento de ortodoncia. Durante la primera consulta, te mostrará lo que puedes esperar del tratamiento, ¡además de comprobar si tu salud bucal está al día! Ah, y ese es el día que eliges si continúas o no tu viaje con nosotros. Al elegir continuar, tus alineadores ahora están listos para la producción."
	},
	{
		pregunta:"¿Con qué frecuencia se cambian los alineadores?",
		respuesta:"Los alineadores se cambian cada 14 días."
	},
	{
		pregunta:"¿Cuánto cuesta el tratamiento con alineadores?",
		respuesta:"Trabajamos con planes de tratamiento, donde el precio varía dependiendo de la complejidad del caso. Por motivos regulatorios, no podemos revelar el precio en el sitio web, pero si desea recibir más información, simplemente tocá el botón de contacto."
	},
	{
		pregunta:"¿Cómo se si el tratamiento con alineadores resolverán mi caso?",
		respuesta:"Descubrirás si nuestro tratamiento resolverán tu caso cuando contactes por primera vez con nosotros. Esta etapa del proceso no es más que una conversación con uno de nuestros expertos."
	},
	{
		pregunta:"¿Qué cuidados debo tener al finalizar el tratamiento?",
		respuesta:"Luego de finalizar el tratamiento, deberás utilizar las placas de retención y seguir las demás recomendaciones de nuestros profesionales."
	},
	{
		pregunta:"¿Qué pasa si pierdo mi alineador?",
		respuesta:"Si el paciente pierde un alineador, debe informarnos rápidamente, para que podamos producir uno nuevo."
	},
	{
		pregunta:"¿Puedo usar alineadores invisibles si tengo implantes, muelas de juicio o me falta un diente?",
		respuesta:"¡Sí! Sin embargo, esta información debe transmitirse a nuestros especialistas durante el primer contacto, para que tu tratamiento pueda planificarse correctamente."
	},
	{
		pregunta:"¿El tratamieinto con alineadores sustituye los brackets fijos?",
		respuesta:"Eso depende. En algunos casos, el tratamiento con alineadores es incluso más aconsejable y predecible que el tratamiento con brackets fijos. Cabe destacar que los alineadores, al igual que los brackets fijos, son una herramienta. Corresponde al ortodoncista saber qué herramienta utilizar para solucionar cada tipo de problema."
	},
	
	
	]

	return (
		<Box className="fondoPreguntas" style={{ width: "100vw", display: "flex", justifyContent: "center", flexDirection: "column", alignItems: !isMobile ? "center" : "flex-start", paddingTop: "5vh", paddingBottom: "5vh", position: 'relative', zIndex: 1 }}>
			<Box style={{ width: "50vw", display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "flex-start" }}>
				<Typography sx={{ fontFamily: "Nunito", gap: '5px', display: 'flex', fontSize: isMobile ? "18px" : '25px', fontWeight: '400', alignItems: 'center', color: '#0077B6', marginBottom: "20px", paddingLeft: isMobile && "10px", zIndex: 2 }}>
					Preguntas Frecuentes
				</Typography>
			</Box>

			<Box style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
				{preguntas?.map((p, index) => (
					<Accordion key={index} sx={{ borderRadius: "60px !important", backgroundColor: 'transparent', width: !isMobile ? "50vw" : "100vw", '::before': { backgroundColor: 'transparent !important' } }}>
						<AccordionSummary
							expandIcon={<KeyboardArrowRightRoundedIcon sx={{ fontSize: '18px', fill: '#00B4D8 !important' }} />}
							aria-controls="panel1-content"
							id="panel1-header"
							sx={{
								backgroundColor: "#fff",
								boxShadow: "rgba(0, 0, 0, 0.25)",
								border: 'none !important',
								display: 'flex',
								justifyContent: 'space-between',
								alignItems: 'center',
								width: "100%",
								borderRadius: '60px',
								'& .MuiSvgIcon-root': {
									fontSize: '18px',
									color: '#00B4D8 !important'
								}
							}}
						>
							<Box className="shadoww" style={{ width: "100%", justifyContent: "space-between", display: "flex", borderRadius: '60px' }}>
								<Typography sx={{ fontFamily: "Nunito", gap: '5px', display: 'flex', fontSize: isMobile ? "14px" : '19px', fontWeight: '400', alignItems: 'center', color: '#00B4D8' }}>
									{p?.pregunta}
								</Typography>
							</Box>
						</AccordionSummary>
						<AccordionDetails style={{ borderRadius: '60px', backgroundColor: '#0077B6' }}>
							<Box className="shadoww" style={{ borderRadius: '60px', cursor: "pointer", width: "100%", justifyContent: "space-between", display: "flex", padding: '0px 0px 0px 0px' }}>
								<Box style={{ display: "flex", gap: "10px" }}>
									<Typography sx={{ fontFamily: "Nunito", gap: '5px', display: 'flex', fontSize: isMobile ? '14px' : "19px", fontWeight: '400', alignItems: 'center', color: '#fff' }}>
										{p?.respuesta}
									</Typography>
								</Box>
							</Box>
						</AccordionDetails>
					</Accordion>
				))}
			</Box>
		</Box>
	);
}

export default Preguntas;
