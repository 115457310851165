import React from 'react';
import NavBar from '../../components/navBar/NavBar';
import Footer from '../../components/footer/Footer';

import { Link, useNavigate } from 'react-router-dom';
import './Contact.Module.css';
import { Toaster, toast } from 'react-hot-toast';

import axios from 'axios';
import { Button, Form } from 'react-bootstrap';
import Loader from '../../components/loader/Loader';
import { isAuthenticated } from '../../utils/usuario/usuario';
import { getDatos } from '../../utils/productos/productos';
import { useMediaQuery } from 'react-responsive';
function Contact() {
	const [datosContacto, setDatosContacto] = React.useState([]);
	const token = localStorage.getItem('Token');
	const navigate = useNavigate();
	const [loader, setLoader] = React.useState(true);

	const [isEditing, setIsEditing] = React.useState(false); // Estado para controlar la edición
	const [editedDatosContacto, setEditedDatosContacto] = React.useState({
		telefono: '',
		correo: '',
		instagram:"",
	});

	const config = {
		headers: {
			xtoken: `${token}`,
		},
	};
	const isMobile = useMediaQuery({ query: '(max-width: 767px)' });
	const handleInputChange = (e) => {
		// Manejar cambios en los campos de entrada editados
		const { name, value } = e.target;
		setEditedDatosContacto({
			...editedDatosContacto,
			[name]: value,
		});
	};

	const updateDatosContacto = async () => {
		try {
			// Construye la URL completa para la actualización
			const url = `${process.env.REACT_APP_BACK_URL}/api/datoscontacto/${datosContacto?._id}`;

			// Realiza una solicitud PUT al servidor para actualizar los datos
			const response = await axios.put(url, editedDatosContacto, config);
			setDatosContacto(editedDatosContacto);
			setIsEditing(false);
			// console.log(response);

			if (response.status === 201) {
				toast.success('Datos actualizados con éxito');
				setIsEditing(false);
			}
		} catch (error) {
			toast.error('Hubo un error', error);
		}
	};

	const isSuperAdmin = isAuthenticated();

	React.useEffect(() => {
		setLoader(true);
		toast.remove();
		async function obtenerDatos() {
			setDatosContacto(await getDatos());
			setEditedDatosContacto(await getDatos());
			setLoader(false);
		}
		obtenerDatos();
	}, []);

	return (
		<div className='fondoContact'>
			<header>
				<NavBar />
			</header>
			{loader ? (
				<Loader />
			) : (
				<>
					<main className={`mainContact`}>
						<Toaster
							position='bottom-left'
							reverseOrder={false}
							toastOptions={{ className: 'fondoToast' }}
						/>
						<div  className='container contactoContainer py-4 shadow'>
							<p style={{fontFamily: "Nunito", fontSize:!isMobile?"20px":"14px"}}>Encontranos en cualquiera de estas opciones:</p>
							<ul className='nav justify-content-center list-unstyled d-flex mb-4 containerListaRedes'>
								<li className='ms-3'>
									<Link
										className='text-muted'
										to='https://www.instagram.com/ortodoncia_invisible_rosario?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw=='
										target='_blank'
									>
										<i style={{fontSize:"45px"}} className='bx bxl-instagram'></i>
									</Link>
								</li>

								<li className='ms-3'>
									<Link
										className='text-muted'
										to={`https://api.whatsapp.com/send?phone=+54${datosContacto?.telefono}&text=Hola,%20les escribo por la página web,%20necesito asesoramiento.`}
										target='_blank'
									>
										<i style={{fontSize:"45px"}}  className='bx bxl-whatsapp'></i>
									</Link>
								</li>

								<li className='ms-3'>
									<Link
										className='text-muted'
										to={`mailto:${datosContacto?.correo}`}
										target='_blank'
									>
										<i style={{fontSize:"45px"}}  className='bx bxl-gmail'></i>
									</Link>
								</li>
							</ul>
							<div className='contactoMedios mb-4'>
								<p style={{fontFamily: "Nunito", fontSize:!isMobile?"20px":"14px"}}>Rioja 1218, Rosario - Argentina</p>
								{/* <p style={{fontFamily: "Nunito"}}>{datosContacto?.correo}</p> */}
							</div>
							<div className='contactoMedios mb-4'>
								{/* <p>
									Atención de Lunes a Viernes de 8:30hs a 12:30hs y de 15:30 a 19:30hs y Sábados de
									08:30hs a 12:30hs
								</p> */}
							</div>
							<div className='contactoMapa'>
								
								<iframe
									title='mapa'
									src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3348.140454508772!2d-60.64261192433017!3d-32.947300973592895!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x95b7ab0e11249103%3A0xf3517f43308dedd8!2sOdontolog%C3%ADa%20Dres.%20del%20Pazo!5e0!3m2!1ses-419!2sar!4v1718549562414!5m2!1ses-419!2sar" 
									loading='lazy'
									referrerPolicy='no-referrer-when-downgrade'
								></iframe>
							</div>
						</div>
						{isSuperAdmin && (
							<div className='editDatosContainer'>
								{!isEditing ? (
									<Button className='editDatos' onClick={() => setIsEditing(true)}>
										Editar datos de contacto
									</Button>
								) : (
									<div>
										<Form.Group>
											<Form.Label>Teléfono:</Form.Label>
											<Form.Control
												type='text'
												name='telefono'
												value={editedDatosContacto.telefono}
												onChange={handleInputChange}
											/>
										</Form.Group>
										<Form.Group>
											<Form.Label>Correo:</Form.Label>
											<Form.Control
												type='text'
												name='correo'
												value={editedDatosContacto.correo}
												onChange={handleInputChange}
											/>
											
										</Form.Group>
										<Form.Group>
											<Form.Label>Instagram:</Form.Label>
											<Form.Control
												type='text'
												name='instagram'
												value={editedDatosContacto.instagram}
												onChange={handleInputChange}
											/>
											
										</Form.Group>
										<Button variant='primary' onClick={() => updateDatosContacto()}>
											Guardar Cambios
										</Button>
										<Button variant='secondary' onClick={() => setIsEditing(false)}>
											Cancelar
										</Button>
									</div>
								)}
							</div>
						)}
					</main>
					<footer>
						<Footer />
					</footer>
				</>
			)}
		</div>
	);
}

export default Contact;
