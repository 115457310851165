import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import { Toaster } from 'react-hot-toast';

function App() {
	document.title = 'Ortodoncia Invisible Rosario';
	return (
		<div className='App'>
			<Toaster position='bottom-left' reverseOrder={false} />
		</div>
	);
}

export default App;
