import React from 'react';
import './GloboContacto.Module.css';
import { DropdownButton, Dropdown } from 'react-bootstrap';
import { getDatos } from '../../utils/productos/productos';
import botonContacto from '../../assets/imagenes/CONTA.png';

function GloboContacto() {
	const [respira, setRespira] = React.useState(false);
	const [datosContacto, setDatosContacto] = React.useState([]);

	React.useEffect(() => {
		const interval = setInterval(() => {
			setRespira((prevState) => !prevState);
		}, 5000);

		return () => {
			clearInterval(interval);
		};
	}, []);

	React.useEffect(() => {
		async function obtenerDatos() {
			setDatosContacto(await getDatos());
		}
		obtenerDatos();
	}, []);

	return (
		<div className='fixedGlobo'>
			<DropdownButton
				className={`grupoGlobo botonGlobo shadow `}
				title={
					<div className={`imgBotonGlobo ${respira ? 'respira' : ''}`}>
						<img src={botonContacto} alt='Boton Contacto' />
					</div>
				}
			>
				<Dropdown.Item
					href={`https://api.whatsapp.com/send?phone=+54${datosContacto?.telefono}&text=Hola,%20les escribo por la página web,%20necesito asesoramiento.`}
					target='_blank'
					className='linkGlobo'
					style={{ width: '40px' }}
				>
					<i className='bx bxl-whatsapp'></i>
				</Dropdown.Item>
				<Dropdown.Item
					href={datosContacto?.instagram}
					target='_blank'
					className='linkGlobo'
					style={{ width: '40px' }}
				>
					<i className='bx bxl-instagram'></i>
				</Dropdown.Item>

				<Dropdown.Item
					href={`mailto:${datosContacto?.correo}`}
					target='_blank'
					className='linkGlobo'
					style={{ width: '40px' }}
				>
					<i className='bx bx-mail-send'></i>
				</Dropdown.Item>
			</DropdownButton>
		</div>
	);
}

export default GloboContacto;
